import { LocalStorageService } from 'ngx-webstorage';
import { Permission, PermissionsService } from '@fitup-monorepo/core/lib/services/permissions/permissions.service';
import { environment } from '@fitup-monorepo/core/lib/environment';
import { hasSelectableTeam, isUsingZppPromotionCode, showTrialPage } from '@fitup-monorepo/core/lib/util/wizard.util';
import { firstValueFrom, of } from 'rxjs';
import { selectTeamCustomer } from '@fitup-monorepo/core/lib/state/profile/profile.selector';
import { ToastService } from '@fitup-monorepo/core/lib/services/toast/toast.service';
import { TeamSelectionService } from '@fitup-monorepo/core/lib/services/team-selection/team-selection.service';
import {
  PromoCodeApplyService,
  showPromocodeOnboardingPage
} from '@fitup-monorepo/onboarding/lib/promo-code/promo-code-apply.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { OnboardingStep, OnboardingWizardService } from '@fitup-monorepo/core/lib/services/wizard/wizard.service';
import { PlatformService } from '@fitup-monorepo/core/lib/services/platform/platform.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FitupOnboardingWizardService extends OnboardingWizardService {
  private readonly onboardingFlow: OnboardingStep[] = [
    {
      index: 0,
      route: '/start-onboard',
      isShortSignupStep: true
    },
    {
      index: 10,
      route: '/user-options-onboarding',
      isVisible: () => this.showOptionsOnboardingPage(),
      isShortSignupStep: true
    },
    {
      index: 20,
      route: '/promo-code/onboard',
      isVisible: () => showPromocodeOnboardingPage(this.store, this.localStorageService, this.promoCodeApplyService),
      isShortSignupStep: true
    },
    {
      index: 21,
      route: '/subscription-free-trial',
      isVisible: () => showTrialPage(this.store),
      isShortSignupStep: false
    },
    {
      index: 22,
      route: '/subscription-zpp',
      isVisible: () => isUsingZppPromotionCode(this.store),
      isShortSignupStep: false
    },
    {
      index: 23,
      route: '/success-subscribe-zpp',
      isVisible: () => isUsingZppPromotionCode(this.store),
      isShortSignupStep: false
    },
    {
      index: 24,
      route: '/exclusion-criteria',
      isVisible: () => isUsingZppPromotionCode(this.store),
      isShortSignupStep: false
    },
    {
      index: 25,
      route: '/success-subscribe',
      isVisible: () => firstValueFrom(of(false)), //we come to this page from stripe, we have in the wizard to know where to continue
      isShortSignupStep: false
    },
    {
      index: 30,
      route: '/account-details-editor',
      isShortSignupStep: false
    },
    {
      index: 40,
      route: '/gender-and-birthdate',
      permission: Permission.basic,
      isShortSignupStep: false
    },
    {
      index: 50,
      route: '/measurements',
      permission: Permission.basic,
      isShortSignupStep: false
    },
    {
      index: 60,
      route: '/fitness-goals',
      permission: Permission.basic,
      isShortSignupStep: false
    },
    {
      index: 70,
      route: '/fitness-level',
      permission: Permission.basic,
      isShortSignupStep: false
    },
    {
      index: 80,
      route: '/interests',
      permission: Permission.basic,
      isShortSignupStep: false
    },
    {
      index: 90,
      route: '/edit-profile-picture',
      isShortSignupStep: false
    },
    {
      index: 100,
      route: '/team-selection/onboard/true',
      permission: Permission.teamChallenge,
      isVisible: () => hasSelectableTeam(this.teamSelectionService, this.toastService),
      isShortSignupStep: false
    },
    {
      index: 110,
      route: '/congratulations',
      permission: Permission.teamChallenge,
      isVisible: async (): Promise<boolean> => {
        const teamCustomer = await firstValueFrom(this.store.select(selectTeamCustomer));
        return !!teamCustomer?.team;
      },
      isShortSignupStep: false
    },
    {
      index: 120,
      route: '/reminder',
      permission: Permission.fitAtWorkReminder,
      isShortSignupStep: false
    },
    {
      index: 130,
      route: '/finalize-onboard',
      permission: Permission.basic,
      isShortSignupStep: false
    },
    {
      index: 140,
      route: '/success-onboard',
      isShortSignupStep: false
    }
  ];

  constructor(
    private readonly platformService: PlatformService,
    private readonly localStorageService: LocalStorageService,
    private readonly toastService: ToastService,
    private readonly teamSelectionService: TeamSelectionService,
    readonly permissionsService: PermissionsService,
    private readonly promoCodeApplyService: PromoCodeApplyService,
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    private readonly store: Store
  ) {
    super(router, activatedRoute, permissionsService);
  }

  public getSteps(): OnboardingStep[] {
    return this.onboardingFlow;
  }

  private async showOptionsOnboardingPage(): Promise<boolean> {
    if (!(environment.isCustomApp || !this.platformService.isNative())) {
      return showPromocodeOnboardingPage(this.store, this.localStorageService, this.promoCodeApplyService);
    }
    return true;
  }
}
